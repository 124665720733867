import React from 'react'
import'./footer.css'
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import Logo from '.././Assets/images/logo.png'

export default function Footer() {
  return <>
{/* <div class=" pt-5 pb-5 footer">
<div class="container">
  <div class="row">
    <div class="col-lg-5 col-xs-12 about-company">
      <h2>Cloud Calls Solutions</h2>
      <p class="pr-5 text-white-50">Scale Up Your Business with our end-to-end business process outsourcing services. </p>
    </div>
    <div class="col-lg-3 col-xs-12 links">
      <h4 class="mt-lg-0 mt-sm-3">Links</h4>
        <ul class="m-0 p-0">
          <li>- <a href="#">Lorem ipsum</a></li>
          <li>- <a href="#">Nam mauris velit</a></li>
          <li>- <a href="#">Etiam vitae mauris</a></li>
          <li>- <a href="#">Fusce scelerisque</a></li>
          <li>- <a href="#">Sed faucibus</a></li>
          <li>- <a href="#">Mauris efficitur nulla</a></li>
        </ul>
    </div>
    <div class="col-lg-4 col-xs-12 location">
      <h4 class="mt-lg-0 mt-sm-4">Location</h4>
      <p>22, Lorem ipsum dolor, consectetur adipiscing</p>
      <p class="mb-0"><i class="fa fa-phone mr-3"></i>(541) 754-3010</p>
      <p><i class="fa fa-envelope-o mr-3"></i>info@hsdf.com</p>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col copyright">
      <p class=""><small class="text-white-50">© 2019. All Rights Reserved.</small></p>
    </div>
  </div>
</div>
</div> */}

<div className="footer">
  <div className="container">
    <div className="row text-center">
   
      <div className="col-12"><h1 className='footer-header'>
      <Fade delay={1e1} cascade damping={1e-2}>
    <img src={Logo} width={180} alt="" />
</Fade></h1></div>
      <div className="col-12"><p className='my-4'>Cloud calls solutions is a renowned leader in the BPO industry, providing exceptional services to diverse sectors and global markets. Cloud calls solutions  has established itself as a trusted provider in the industry.</p></div>
      <div className="col-12">
        <ul className='d-flex justify-content-center gap-4 '>
          <li className=' d-flex  justify-content-center align-items-center'><a href="mailto:info@elevateholding.net"><i className='fa-solid fa-envelope ico text-white font-24 px-1'></i> info@cloudcallssolutions.net</a></li>
          <li className=' d-flex  justify-content-center align-items-center'> <i className='fa-solid fa-phone ico font-24 px-1  '></i> <a href="tel:+(+1) 209-418-7309"> (+1) 209-418-7309</a></li>
        </ul>
        <ul className='d-flex justify-content-center gap-5 contact-footer'>
          <li><a href=""><i className='fab fa-facebook'></i></a></li>
          <li><a href=""><i className='fab fa-instagram'></i></a></li>
          <li><a href=""><i className='fab fa-linkedin'></i></a></li>
        </ul>
        <p className='text-center frame'>© 2024 Cloud Calls Solutions. All Rights Reserved.</p>
      </div>
      <div className="col-12"></div>
      <div className="col-12"></div>
      <div className="col-12"></div>
    </div>
  </div>
</div>
 
  </>
}
