import React from "react";
import "./WhyWithUsHomeSec.css";
import { Link } from "react-router-dom";
import parnter from "../../Assets/images/partner.jpg";
import { Slide } from "react-awesome-reveal";

export default function WhyWithUsHomeSec() {
  return (
    <>
      <div className="WhyWithUsHomeSec position-relative mt-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div>
                <h2 className="global-use">Why partner with us?</h2>
                <ul>
                  <Slide>
                    <li>Tailored Solutions</li>
                  </Slide>

                  <Slide>
                    <li>Scalability and Flexibility</li>
                  </Slide>
<Slide>

                  <li>Quality Assurance</li>
</Slide>
<Slide>

                  <li>Advanced Technology</li>
</Slide>

                  <Slide>
                    <li>Security and Confidentiality</li>
                  </Slide>
<Slide>

                  <li>Cost Efficiency</li>
</Slide>
                </ul>
                <Link to="/contact">
                  <button className="btn btn-partner">Contact Us</button>
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <img className="w-100" src={parnter} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
