import React, { useEffect } from "react";
import'./header.css'
import { Link } from "react-router-dom";
import Img from '../../Assets/images/6248708.jpg'
import { Fade } from "react-awesome-reveal";



export default function Header() {
  
  
  
  
  return (
    <>
    <header>
    <div className="container  ">
      
        <div className="row">
          <div   className="col-12 col-lg-7 pt-5">
            <h1    className="header-main-content">
              Scale Up Your Business with our end-to-end business process
              outsourcing services.
            </h1>
            <p  className="header-secondary-content">Our end-to-end BPO services will free up your time and resources so you can focus on growth.</p>
            <div className="buttons">
              <Link to="/contact">
                <button className="btn btn-orange">Contact Us</button>
              </Link>
              <Link to="/contact">
                <button className="btn  text-black learn-more">Learn More</button>
              </Link>
            </div>
          </div>
          <div className=" col-12 col-lg-5">
            <img src={Img} className="w-100" alt="" />
          </div>

        </div>
        
      </div>
    </header>
     
    </>
  );
}
