import React, { useEffect } from 'react'
import'./HomeServSec.css'
import { Flip, Slide } from 'react-awesome-reveal'


export default function HomeServSec() {
 
 
  return <>
  <div className="home-srev-sec">
    <h2 className='headingHomeSec'>Services That We Provide</h2>
    <p className='headingPara'>We offer a wide range of customer experience solutions to help you improve your customer satisfaction, loyalty, and retention.</p>
  <div className="container">
    <div className="row mt-4">
        <div  className="col-md-4 text-center ">
      <Slide direction='left' duration={500}>
            <div className='bg-danger shadow bg-white p-4 h-100'>
            <i class="fa-solid fa-headset ico-serv"></i>
            {/* <img src="https://elevateholding.net/wp-content/uploads/2023/09/Group-29-2-100x90.png" alt="" /> */}
           <h5>Contact Center Services</h5>
           <p>24/7 support, multilingual agents, and a personalized approach to customer service.</p>

            </div>
          

        </Slide>
        </div>
      
        <div   className="col-md-4   text-center ">
        <Slide direction='up' duration={500}>
            <div className='bg-danger shadow bg-white p-4 h-100'>
            <i class="bi bi-cash-stack ico-serv"></i>
            {/* <img src="https://elevateholding.net/wp-content/uploads/2023/09/Group-28-1-100x90.png" alt="" /> */}
           <h5>TeleSales Servcies</h5>
           <p>Refine telesales with tailored pitches, effective communication, and strategic follow-up.</p>

            </div>
          

        </Slide>
        </div>
      
        <div className="col-md-4  text-center ">
        <Slide direction='right' duration={500}>
            <div className='bg-danger shadow bg-white p-4 h-100'>
            {/* <img src="https://elevateholding.net/wp-content/uploads/2023/09/Group-27-100x90.png" alt="" /> */}
          
            <i class="bi bi-building ico-serv"></i>
           <h5>internal outsourcing</h5>
           <p>Optimize internal outsourcing by aligning tasks, fostering communication, and ensuring efficiency.</p>

            </div>
          

        </Slide>
        </div>
      
        
    </div>
  </div>
  </div>
  </>
}
