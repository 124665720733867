import React, { useEffect, useState } from 'react'
import Header from '../Header/Header';
import HomeServSec from '../WhatWeDo/HomeServSec';
import HoldingHomeSec from '../HoldingHomeSec/HoldingHomeSec';
import WhyWithUsHomeSec from '../WhyWithUsHomeSec/WhyWithUsHomeSec';
import {motion} from 'framer-motion'
import { ContactUs } from '../Contact/ContactUs';


export default function Home() {
  useEffect(()=>{
    window.scrollTo(0,0)

  },[])

  return <>
 
 
  <motion.div className=""
  initial={{opacity:0}}
  animate={{opacity:1}}
  exit={{opacity:0}}
  
  >
  <Header/>
  <HomeServSec/>
  <HoldingHomeSec/>
  <WhyWithUsHomeSec/>
  <ContactUs/>
  </motion.div>
 

  
  </>
  
 
}
