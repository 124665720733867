import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './Home/Home';
import About from './About/About';
import { ContactUs } from './Contact/ContactUs';
import { AnimatePresence } from 'framer-motion'

export default function AnimatedRoutes() {
    let location = useLocation()

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<ContactUs />} />
            </Routes>

        </AnimatePresence>

    )
}
