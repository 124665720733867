// import React from "react";
// import NavbarStlyle from "./Navbar.css";
// import { NavLink } from "react-router-dom";


// export default function Navbar() {
//   return (
//     <>
    
//     <div className="container-fluid p-2 ">
//     <nav className="row  p-0 m-0 ">
//         <div className="col">
//             <h1 className="logo-name">Cloud Calls Solutions</h1>
//         {/* <img className="logo-img me-auto" src="https://elevateholding.net/wp-content/uploads/2023/09/cropped-Elevate-Holding-Dark-Logo-Transparent-Background-1.png" alt="" /> */}
//         </div>
//         <div className="col gap-4">
//             <NavLink  className="nav-link" to="">Home</NavLink>
//             <NavLink  className="nav-link" to="/about">About Us</NavLink>
//             <NavLink  className="nav-link" to="/contact">Contact Us</NavLink>
//         </div>
//         <div className="col">
//             <NavLink to="/contact">

//             <button className="ms-auto btn btn-orange    ">Get Started</button>
//             </NavLink>
//         </div>
 
// </nav>

//     </div>
   
      
        
//     </>
//   );
// }

//    {/* <div className="logo-img">
//         <img src="https://elevateholding.net/wp-content/uploads/2023/09/cropped-Elevate-Holding-Dark-Logo-Transparent-Background-1.png" alt="" />
//     </div>
//     <div className="links-container d-flex bg-info align-items-center"> 
//         <ul className="nav-links d-flex bg-danger gap-4">
//             <li>
//                 <a href="">Home</a>
//             </li>
//             <li>
//                 <a href="">About</a>
//             </li>
//             <li>
//                 <a href="">Contact</a>
//             </li>
//         </ul>
//     </div>
//     <div className="starteBtn">
//         <button className="btn btn-danger">Get Started</button>
//     </div> */}
import React from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom'
import Logo from '../../Assets/images/logo.png'

export default function Navbar() {
  return <>
  <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
  <div className="container-fluid">
    <Link className="navbar-brand" to=""
    >
      {/* <h1 className="logo-name">
      Cloud Calls Solutions</h1> */}
      <img src={Logo} className='' width={120}  alt="" />
      </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0 p-0 mx-auto">
        <li className="nav-item mx-4">
          <Link className="nav-link " aria-current="page" to="">Home</Link>
        </li>
        <li className="nav-item mx-4">
          <Link className="nav-link" to="/about">About Us</Link>
        </li>
        <li className="nav-item mx-4">
          <Link className="nav-link" to="/contact">Contact Us</Link>
        </li>
         
      </ul>
      <Link to={"/contact"}>
      <button className='btn btn-orange'>Get Started</button>
      </Link>
    </div>
  </div>
</nav>
  
  </>
}
