import React from 'react'
import'./HoldingHomeSec.css'
import { Link } from 'react-router-dom'
import { Bounce, Fade, Flip } from 'react-awesome-reveal'
import Logo from '../../Assets/images/logo.png'

export default function HoldingHomeSec() {
  return <>
  <div className="holding-home-sec ">
    <div className="container ">
      <Flip duration={800}>
    {/* <div className="holding my-5">

        <h5>Cloud Calls Solutions</h5>
    </div> */}
    <img src={Logo} width={300} className=' mx-auto d-block' alt="" />
      </Flip  >
      <Fade duration={2000}>

    <h2  className='my-5'>Contact us today to learn more about how we can help you with your business operations’ needs.</h2>
      </Fade>
    <p className='my-5'>Colud Call Solutions is a leading business process outsourcing (BPO) company that offers a wide range of services to help businesses of all sizes succeed.</p>
    <Link style={{textDecoration:"none"}} to="/contact">
      <Bounce>

    <button   className='btn btn-holding  mx-auto d-block'>Get A Free Quote</button>
      </Bounce>
    </Link>
    </div>
  </div>
  </>
}
