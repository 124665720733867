import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { useLocation, useParams } from "react-router-dom";
import "./contactUs.css";
import * as yup from "yup";
import { useFormik } from "formik";
import { Fade } from "react-awesome-reveal";
import Logo from '../../Assets/images/logo.png'

export const ContactUs = () => {
  useEffect(()=>{
    window.scrollTo(0,0)

  },[])
  const form = useRef();
  let x = useLocation();
  let [successMessage, setSuccessMessage] = useState(null);
  let [loader, setLoader] = useState(false);
  let [numberMessage , setNumberMesasge] = useState(false)

  async function submitMessage(values) {
    console.log(values);
    setLoader(true);
    await emailjs
      .sendForm("service_hoyv1g2", "template_i5wuzco", form.current, {
        publicKey: "JPLtl1u9WKs0mYMj6",
      })
      .then(
        () => {
          setSuccessMessage(
            <div class="alert alert-success m-3" role="alert">
              ThankYou For Contacting Us
            </div>
          );
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
          setSuccessMessage(
            <div class="alert alert-danger m-3" role="alert">
              Message Not sent
            </div>
          );
        }
      );
    setLoader(false);
  }
  let validationSchema = yup.object({
    from_name: yup
      .string()
      .min(3, "At Least Three Charachter ")
      .max(100, "The Name Is Too Long ")
      .required("Name Is Reqired"),
    user_email: yup
      .string()
      .email("Please Enter A Valid Email")
      .required("Email Is Requierd"),
    message: yup
      .string()
      .min(10, "This Message Is Too Short")
      .required("Message is Requied"),
  });
  let formik = useFormik({
    initialValues: {
      from_name: "",
      user_email: "",
      message: "",
    },
    validationSchema,
    onSubmit: submitMessage,
  });
  return (
    <>
      <div className="contact-us">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div>
                {/* <div className="holding  m-0">
                  <h5>Cloud Calls Solutions</h5>
                </div> */}
                <img src={Logo} width={200} alt="" />
                <h4>
                

                  Let's connect and build something great together. Contact
                  CCS today.
                
                </h4>

                <h6>
                  We take all inquiries seriously and will respond as soon as
                  possible. One of our consultants will contact you to discuss
                  your needs and answer any questions you may have. We are here
                  to help you succeed.
                </h6>

                <ul className="p-0">
                  <li>
                    <i class="fa-solid fa-location-dot ico"></i>
                    <span>
                      284 South academy B , New cairo , Cairo Egypt 
                    </span>
                  </li>
                  <li>
                    <i class="fa-solid fa-envelope ico"></i>
                    <a href="mailto:info@cloudcallssolutions.net">
                      info@cloudcallssolutions.net
                    </a>
                  </li>
                  <li>
                  <i class="fa-solid fa-phone ico"></i>
                    <a href="tel:+(+1) 209-418-7309 ">(+1) 209-418-7309</a>
                  </li>
                </ul>
                <ul className="d-flex gap-5">
                  <li>
                    <i className="fab fa-facebook"></i> <span>Facebook</span>
                  </li>
                  <li>
                    <i className="fab fa-instagram"></i> <span>Instagram</span>
                  </li>
                  <li>
                    <i className="fab fa-linkedin"></i> <span>LinkedIn</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className=" h-100 p-5 bg-white rounded-4">
                <form ref={form} onSubmit={formik.handleSubmit}>
                  <div className="row contact-inputs ">
                    <input
                      onFocus={() => {
                        setSuccessMessage(null);
                      }}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.from_name}
                      type="text"
                      name="from_name"
                      placeholder="Name"
                    />
                    <input
                      onFocus={() => {
                        setSuccessMessage(null);
                      }}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.user_email}
                      type="email"
                      name="user_email"
                      placeholder="Email"
                    />
                    {numberMessage?<span className=" m-0 p-0 text-danger">Please include your phone number for better assistance</span>:""}
                    <textarea
                      onFocus={() => {
                        setSuccessMessage(null);
                        setNumberMesasge(true)
                      }}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.message}
                      name="message"
                      placeholder="Message"
                    />
                  </div>
                  <button
                    className="btn btn-orange mt-4 "
                    type="submit"
                    value="Send"
                  >
                    {" "}
                    {loader ? (
                      <>
                        <span className=""> Sending ..</span>
                        <div class="loader"></div>
                      </>
                    ) : (
                      "Send Message"
                    )}
                  </button>

                  {successMessage}
                  {formik.errors.from_name && formik.touched.from_name ? (
                    <div className="alert alert-danger">
                      {formik.errors.from_name}
                    </div>
                  ) : (
                    ""
                  )}
                  {formik.errors.user_email && formik.touched.user_email ? (
                    <div className="alert alert-danger">
                      {formik.errors.user_email}
                    </div>
                  ) : (
                    ""
                  )}
                  {formik.errors.message && formik.touched.message ? (
                    <div className="alert alert-danger">
                      {formik.errors.message}
                    </div>
                  ) : (
                    ""
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
