import React from 'react'
import { motion } from 'framer-motion';
import './abloutUs.css'
import HoldingHomeSec from './../HoldingHomeSec/HoldingHomeSec';
import objImg from '../../Assets/images/objective about.jpg'
import visionImg from '../../Assets/images/business vision about.jpg'
import valuseImg from '../../Assets/images/valuesImg.jpg'
import { Fade } from 'react-awesome-reveal';
import { useEffect } from 'react';


export default function About() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    
    <motion.div initial={{opacity:0}}
    animate={{opacity:1}}
    exit={{opacity:0}}>
       <div className="aboutUs">
<div className="about-header text-center">
<div className="container">
<h1>
<Fade delay={1e1} cascade damping={1e-1}>
About Us
</Fade>
  </h1>

<p className='text-white'>
  Introducing Cloud Calls Solutions, a vibrant BPO provider headquartered in Egypt. Passionate about propelling businesses to new heights, we boast extensive expertise to conquer challenges and achieve outstanding outcomes. Beyond a conventional BPO provider, we aspire to be your trusted ally, delving deep into your business and objectives. Through close collaboration, we craft tailored solutions, precisely addressing your distinctive requirements. Committed to excellence, Cloud Calls Solutions stands by its commitment to client satisfaction with a guaranteed service.
  </p>


</div>
</div>
<div className="container">
<div className="row gy-3 my-5">
<div className="col-md-6">
<div>
<img className='w-100' src={visionImg} alt="" />
</div>
</div>
<div className="col-md-6">
<div>
<h1 className='about-section-heading'>Our Vision</h1>
<p className='about-section-paragraph'>Our vision is to revolutionize the outsourcing industry by providing world-class innovative and customized solutions that empower businesses to thrive in today's dynamic marketplace. We strive to be the partner of choice for organizations seeking to optimize their processes, reduce costs, and enhance overall efficiency.</p>
</div>
</div>

</div>
<div className="row gy-3 my-5">

<div className="col-md-6">
<div>
<h1 className='about-section-heading'>Our Mission</h1>
<p className='about-section-paragraph'>We strive to achieve excellence by delivering outstanding customer experiences through personalized support. Our aim is to be your reliable call center partner, dedicated to both satisfaction and operational excellence.</p>
</div>
</div>
<div className="col-md-6">
<div>
<img className='w-100' src={objImg} alt="" />
</div>
</div>

</div>
<div className="row gy-3 my-5">
<div className="col-md-6">
<div>
<img className='w-100' src={valuseImg} alt="" loading='lazy' />
</div>
</div>
<div className="col-md-6">
<div>
<h1 className='about-section-heading'>Our Value</h1>

<p className='about-section-paragraph'>

  A Comprehensive Hub for BPO Solutions, Backed by Seasoned Professionals Ensuring Precision.


Despite being a youthful enterprise, our team boasts decades of industry experience, enabling us to stand toe-to-toe with industry giants and deliver exceptional service to our clientele.
Our dedication to client contentment, continual enhancement, and the integration of cutting-edge technology distinguishes us from our peers.
CCS specializes in diverse Business Process Outsourcing services (BPO), spanning customer engagement, human resources, finance, and IT outsourcing. Whatever your demands, we're equipped to provide tailored solutions.
Count on our seasoned proficiency to fulfill your distinct needs.
</p>


</div>
</div>

</div>
<div className="WhyWithUsHomeSec position-relative mt-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col">
              <div>
                <h2  className="global-use">
                Why Choose Us?
                </h2>
                <ul>
                  <li>Customized Solutions: Recognizing the uniqueness of every business, we embrace a personalized strategy. Working closely with clients, we craft custom solutions addressing their distinct needs and delivering measurable outcomes.</li>
                  <li>Adaptability and Versatility: With the evolution of your business comes evolving needs. Our adaptable solutions are designed to grow seamlessly with your business, enabling quick adjustments to changing market dynamics. Choose from our flexible engagement models to find the service level that best suits you.</li>
                  <li>Scalability and Flexibility: As your business evolves, so do your needs. We offer scalable solutions that can grow with your business, allowing you to adapt to changing market dynamics quickly. Our flexible engagement models ensure that you have the freedom to choose the level of service that suits you best.</li>
                  <li>Quality Assurance: At CCS, we prioritize quality at every step of the process. Our stringent quality assurance measures ensure that all tasks are executed with precision and accuracy, exceeding industry standards and meeting the highest level of customer satisfaction.</li>
                  <li>Cutting-edge Technology: We harness the latest technological advancements to streamline processes, automate tasks, and boost efficiency. Our state-of-the-art infrastructure and robust IT systems seamlessly integrate with your existing operations.</li>
                  <li>Operational Cost Effectiveness: Partnering with us unleashes substantial cost savings. Leveraging our global resources and economies of scale, we deliver cost-effective solutions, empowering you to optimize your budget and allocate resources strategically.</li>
                </ul>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <HoldingHomeSec/>

</div>


</div> 
       

    
      
    </motion.div>
      </>
  )
}

//  <div className="aboutUs">
// <div className="about-headerddd text-center">
// <div className="container">
// <h1>Who We Are?</h1>
// <p>Elevate Holding is a young and dynamic BPO provider based in Egypt. We're passionate about helping businesses of all sizes elevate their operations to new heights. With our extensive experience and expertise, we have the capability to tackle any challenge and deliver exceptional results. At Elevate Holding, we pride ourselves on being more than just another BPO provider. We strive to be a trusted partner who deeply understands your business and goals. By working closely with you, we can develop a customized solution that precisely caters to your specific needs. We're committed to providing our clients with the best possible service. That's why we offer a satisfaction guarantee on all our services.</p>

// </div>
// </div>
// <div className="container">
// <div className="row gy-3 my-5">
// <div className="col-md-6">
// <div>
// <img className='w-100' src="https://elevateholding.net/wp-content/uploads/2023/10/mission-rep-01.jpg" alt="" />
// </div>
// </div>
// <div className="col-md-6">
// <div>
// <h1 className='about-section-heading'>Our Vision</h1>
// <p className='about-section-paragraph'>Our vision is to revolutionize the outsourcing industry by providing world-class innovative and customized solutions that empower businesses to thrive in today's dynamic marketplace. We strive to be the partner of choice for organizations seeking to optimize their processes, reduce costs, and enhance overall efficiency.</p>
// </div>
// </div>

// </div>
// <div className="row gy-3 my-5">

// <div className="col-md-6">
// <div>
// <h1 className='about-section-heading'>Our Vision</h1>
// <p className='about-section-paragraph'>Our vision is to revolutionize the outsourcing industry by providing world-class innovative and customized solutions that empower businesses to thrive in today's dynamic marketplace. We strive to be the partner of choice for organizations seeking to optimize their processes, reduce costs, and enhance overall efficiency.</p>
// </div>
// </div>
// <div className="col-md-6">
// <div>
// <img className='w-100' src="https://elevateholding.net/wp-content/uploads/2023/10/mission-rep-01.jpg" alt="" />
// </div>
// </div>

// </div>
// <div className="row gy-3 my-5">
// <div className="col-md-6">
// <div>
// <img className='w-100' src="https://elevateholding.net/wp-content/uploads/2023/10/mission-rep-01.jpg" alt="" />
// </div>
// </div>
// <div className="col-md-6">
// <div>
// <h1 className='about-section-heading'>Our Vision</h1>
// <p className='about-section-paragraph'>Our vision is to revolutionize the outsourcing industry by providing world-class innovative and customized solutions that empower businesses to thrive in today's dynamic marketplace. We strive to be the partner of choice for organizations seeking to optimize their processes, reduce costs, and enhance overall efficiency.</p>
// </div>
// </div>

// </div>
// </div>


// </div> 