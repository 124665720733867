// import './App.css';
// import Navbar from './Components/Navbar/Navbar';
// import Home from './Components/Home/Home';
// import { RouterProvider, createBrowserRouter, useLocation } from 'react-router-dom';
// import LayOut from './Components/LayOut/LayOut';
// import { ContactUs } from './Components/Contact/ContactUs';
// import About from './Components/About/About';



// let routes = createBrowserRouter([

//   {path:'', element:<LayOut/>,children:[
//     {index:true , element:<Home/>},
//     {path:'contact' , element:<ContactUs/>},
//     {path:'about' , element:<About/>}
//   ]}
// ])

// function App() {
  
//   return<>
//   {<RouterProvider router={routes}/> }
//   </>
// }

// export default App;

import React, { useEffect } from 'react';
import { BrowserRouter} from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import AnimatedRoutes from './Components/AnimatedRoutes';
import Footer from './Footer/Footer';



// Import your components for each route

const App = () => {
    
  return (
    <BrowserRouter>
      <Navbar/>
     <AnimatedRoutes/>
     <Footer/>
    </BrowserRouter>
  );
};

export default App;
